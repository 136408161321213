import React, { FC } from 'react';
import { Paper } from '@mui/material';
import { JobDetailsSharedActions } from 'components/JobDetails/JobDetailsShared/job-details-shared-actions';
import JobDetailsBody from '../../JobDetailsContent/JobDetailsBody/JobDetailsBody';
import { JobDetailsSharedHeader } from '../job-derails-shared-header';
import { IJobDetailsShared } from './job-details-shared.interface';
import { useJobDetailsSharedStyles } from './job-details-shared.styles';

export const JobDetailsShared: FC<IJobDetailsShared> = ({
  job: {
    id,
    job_role: { name },
    company: {
      city: {
        currency,
        name: city,
        country: { name: country },
      },
      logo_url,
      name: companyName,
      id: companyId,
    },
    salary_min,
    salary_max,
    salary_range_hidden,
    skills,
    languages,
    description,
    benefits,
    published_at,
    employment_types,
    employment_levels,
    experience_years_max,
    experience_years_min,
    dialog_info,
    work_model,
    region,
    isExternal = false,
    apply_link,
    time_job_dislike,
    time_job_like,
    regions,
  },
}) => {
  const classes = useJobDetailsSharedStyles();

  return (
    <Paper component="article" className={classes.container}>
      <JobDetailsSharedHeader
        name={name}
        salaryRangeHidden={!!salary_range_hidden}
        salaryMin={salary_min}
        salaryMax={salary_max}
        currency={currency}
        companyName={companyName}
        logoUrl={logo_url}
        companyId={companyId}
        employmentTypes={employment_types}
        employmentLevels={employment_levels}
        city={city}
        country={country}
        experienceYearsMin={experience_years_min}
        experienceYearsMax={experience_years_max}
        workModel={work_model}
        region={region}
        isExternal={isExternal}
        regions={regions}
      />

      <JobDetailsBody
        description={description}
        skills={skills}
        languages={languages}
        benefits={benefits}
        className={classes.body}
        publishedAt={published_at}
      />

      <JobDetailsSharedActions
        jobId={id}
        dialogInfo={dialog_info}
        isExternal={isExternal}
        applyLink={apply_link}
        timeJobDislike={
          time_job_dislike ? new Date(time_job_dislike as Date) : undefined
        }
        timeJobLike={
          time_job_like ? new Date(time_job_like as Date) : undefined
        }
      />
    </Paper>
  );
};
