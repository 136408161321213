import React, { CSSProperties, FC, useMemo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Box, Paper, Typography } from '@mui/material';
import { workModelsIds } from '@nploy/ui/domain';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarStar,
  faClock,
  faFileAlt,
  faMapMarkerAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import workModelImage from 'public/images/icons/work-model.svg';
import palette from 'theme/palette';
import { isNumberValid } from 'utils/helpers';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { JobSalaryText } from 'components/JobsDashboard/job-salary-text';
import { IJobDerailsSharedHeader } from './job-derails-shared-header.interface';
import { jobDerailsSharedHeaderStyles } from './job-derails-shared-header.styles';

export const JobDetailsSharedHeader: FC<IJobDerailsSharedHeader> = ({
  name,
  city,
  country,
  workModel,
  salaryMax,
  companyId,
  companyName,
  currency,
  employmentLevels,
  employmentTypes,
  experienceYearsMax,
  experienceYearsMin,
  logoUrl,
  salaryMin,
  salaryRangeHidden,
  region,
  isExternal,
  regions,
}) => {
  const { t } = useTranslate();
  const { filtersValues } = useFiltersValues();

  const memoizedExperience = useMemo(() => {
    if (isNumberValid(experienceYearsMin) && experienceYearsMax) {
      const yearsCount =
        experienceYearsMin !== experienceYearsMax
          ? `${experienceYearsMin} - ${experienceYearsMax}`
          : experienceYearsMin;
      const yearsValue =
        experienceYearsMin === experienceYearsMax && experienceYearsMin === 1
          ? t('job.year')
          : t('job.years');
      return `${yearsCount} ${yearsValue} `;
    }
    return `0 - 15 ${t('job.years')} `;
  }, [experienceYearsMin, experienceYearsMax]);

  const memoizedLocation = useMemo(() => {
    if (
      (workModel?.id === workModelsIds.remoteWork && region) ||
      regions?.length
    ) {
      return regions?.length
        ? `Remote - ${regions.map((r) => r.name).join(', ')}`
        : `Remote - ${region.name}`;
    }
    return `${city}, ${country}`;
  }, []);

  return (
    <Paper
      elevation={2}
      sx={jobDerailsSharedHeaderStyles.header}
      component="header"
    >
      <Box
        style={{ width: '100%', justifyContent: 'space-between' }}
        sx={jobDerailsSharedHeaderStyles.сontainer}
      >
        <Box mr={2} my={2}>
          {/* Name */}
          <Typography
            variant="h2"
            component="h1"
            sx={jobDerailsSharedHeaderStyles.nameText}
          >
            {name}
          </Typography>

          {/* Salary */}
          <Box sx={jobDerailsSharedHeaderStyles.salaryText}>
            <JobSalaryText
              userSalaryMin={filtersValues.salary_min}
              userSalaryMax={filtersValues.salary_max}
              userCountry={filtersValues.country_id}
              jobSalaryMin={salaryMin}
              jobSalaryMax={salaryMax}
              jobCurrency={currency}
              jobSalaryRangeHidden={salaryRangeHidden}
              jobEmploymentTypes={employmentTypes}
              netTextSx={jobDerailsSharedHeaderStyles.netText}
            />
          </Box>
        </Box>
        <Link
          href={`/top_companies/${companyId}`}
          passHref
          aria-disabled={isExternal}
          prefetch={!isExternal}
          style={{
            textDecoration: 'none',
            ...(isExternal && { pointerEvents: 'none' }),
          }}
        >
          <Box sx={jobDerailsSharedHeaderStyles.companyInfo}>
            {/* Logo */}
            <Box sx={jobDerailsSharedHeaderStyles.logo}>
              <Image
                fill
                style={jobDerailsSharedHeaderStyles.logoImage as CSSProperties}
                src={logoUrl}
                alt={`${t('img.logoOf')} ${companyName}`}
                title={`${t('img.logoOf')} ${companyName}`}
              />
            </Box>
            {/* Company name */}
            <Typography
              variant="body2"
              component="h2"
              color="textSecondary"
              sx={jobDerailsSharedHeaderStyles.companyName}
            >
              {companyName}
            </Typography>
          </Box>
        </Link>
      </Box>

      <Box sx={jobDerailsSharedHeaderStyles.сontainer}>
        {workModel && (
          <Box sx={jobDerailsSharedHeaderStyles.rowContainer}>
            <Box>
              <Image src={workModelImage} alt={workModel.name} />
            </Box>

            <Typography
              variant="body2"
              noWrap
              sx={jobDerailsSharedHeaderStyles.containerMargins}
            >
              {workModel.name}
            </Typography>
          </Box>
        )}
        {/* Employment types */}
        <Box sx={jobDerailsSharedHeaderStyles.rowContainer}>
          <Box>
            <FontAwesomeIcon
              icon={faFileAlt as IconProp}
              color={palette.darkGrey.main}
            />
          </Box>

          <Typography
            variant="body2"
            noWrap
            sx={jobDerailsSharedHeaderStyles.containerMargins}
          >
            {employmentTypes
              .map((eType) => t(`job.employmentTypes.${eType.name}`))
              .join(' / ')}
          </Typography>
        </Box>

        {/* Experience */}
        <Box sx={jobDerailsSharedHeaderStyles.rowContainer}>
          <Box>
            <FontAwesomeIcon
              icon={faCalendarStar as IconProp}
              color={palette.darkGrey.main}
            />
          </Box>

          <Typography
            variant="body2"
            noWrap
            sx={jobDerailsSharedHeaderStyles.containerMargins}
          >
            {memoizedExperience}
            <Typography variant="body2" color="textSecondary" component="span">
              {t('job.ofExperience')}
            </Typography>
          </Typography>
        </Box>

        {/* Employment levels */}
        <Box sx={jobDerailsSharedHeaderStyles.rowContainer}>
          <Box>
            <FontAwesomeIcon
              icon={faClock as IconProp}
              color={palette.darkGrey.main}
            />
          </Box>

          <Typography
            noWrap
            variant="body2"
            sx={jobDerailsSharedHeaderStyles.containerMargins}
          >
            {employmentLevels.map((eLevel) => eLevel.name).join(' / ')}
          </Typography>
        </Box>

        {/* Location */}
        <Box sx={jobDerailsSharedHeaderStyles.rowContainer}>
          <Box>
            <FontAwesomeIcon
              icon={faMapMarkerAlt as IconProp}
              color={palette.darkGrey.main}
            />
          </Box>

          <Typography
            noWrap
            variant="body2"
            sx={jobDerailsSharedHeaderStyles.containerMargins}
          >
            {memoizedLocation}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

JobDetailsSharedHeader.defaultProps = {
  name: '',
  salaryRangeHidden: false,
  salaryMin: 0,
  salaryMax: 0,
  currency: '',
};
