import { useDispatch } from 'react-redux';
import { axios } from '@nploy/shared';
import { openFeedback } from 'store/reducers/feedbackReducer';
import {
  setDislikedId,
  setExternalDislikedId,
} from 'store/reducers/jobsReducer';
import { eventNames, logAnalyticsEvent } from '../../analytics/event';
import {
  getFromLocalStorage,
  localStorageKeys,
  setToLocalStorage,
} from '../../localStorage';
import { handleError } from '../helpers/errorHandling';
import { useUser } from './useUser';

const createPostDislike =
  (dispatch) => async (jobId, onSuccess, onFail, isExternal) => {
    try {
      if (isExternal) {
        await axios.post(`/jobs/scraper-job/${jobId}/liked/1`);
      } else {
        await axios.post(`/job/${jobId}/dislike`);
      }
      if (onSuccess) onSuccess();
    } catch (error) {
      handleError(error, dispatch);
      if (onFail) onFail();
    }
  };

export const useDislikeJob = () => {
  const dispatch = useDispatch();
  const {
    user: { isAuthed },
  } = useUser();

  const dislike = async (jobId, onSuccess, onFail, isExternal) => {
    if (isExternal) {
      dispatch(setExternalDislikedId(jobId));
    } else {
      dispatch(setDislikedId(jobId));
    }
    if (isAuthed) {
      const postDislike = createPostDislike(dispatch);
      await postDislike(
        jobId,
        () => {
          logAnalyticsEvent(eventNames.discard, {
            category: jobId,
            label: 'registered_user',
            is_external: isExternal,
          });
          if (onSuccess) onSuccess();
        },
        onFail,
        isExternal,
      );
    } else {
      if (isExternal) {
        const dislikedIds =
          getFromLocalStorage(localStorageKeys.externalDislikedIds) || [];
        setToLocalStorage(localStorageKeys.externalDislikedIds, [
          ...dislikedIds,
          jobId,
        ]);
      } else {
        const dislikedIds =
          getFromLocalStorage(localStorageKeys.dislikedIds) || [];
        setToLocalStorage(localStorageKeys.dislikedIds, [
          ...dislikedIds,
          jobId,
        ]);
      }

      logAnalyticsEvent(eventNames.discard, {
        category: jobId,
        label: 'unregistered_user',
        is_external: isExternal,
      });

      if (onSuccess) onSuccess();
    }

    dispatch(openFeedback({ type: 'success', message: 'jobAdDiscarded' }));
  };

  return {
    dislike,
  };
};
