import { useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  ICandidateExternalJobDto,
  ICandidateJobDto,
} from '@nploy/ui-infrastructure';
import { fetchData } from '../helpers/fetchData';

export const useJobDetails = (jobId?: number, isExternal?: boolean) => {
  const [endpoint, setEndpoint] = useState<null | string>(null);

  const { data, error, mutate } = useSWR<
    ICandidateJobDto | ICandidateExternalJobDto
  >(endpoint, fetchData);

  useEffect(() => {
    if (jobId) {
      if (isExternal) {
        setEndpoint(`jobs/scraper-job/${jobId}`);
      } else {
        setEndpoint(`job/${jobId}`);
      }
    } else {
      setEndpoint(null);
    }
  }, [jobId, isExternal]);

  return {
    jobDetails: data
      ? {
          ...data,
          isExternal,
          ...(isExternal &&
            'description_html' in data && {
              description: data.description_html,
            }),
        }
      : ({} as ICandidateJobDto),
    loading: !data && !error,
    mutate,
  };
};
