import React, { forwardRef } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { MenuItem, MenuList } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFacebook,
  faLinkedin,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import isWindow from 'utils/isWindow';
import { useAppDispatch } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { ShareJobItem } from '../share-job-item';
import { IShareJobMenu } from './share-job-menu.interface';
import { shareJobMenuStyles } from './share-job-menu.styles';

export const ShareJobMenu = forwardRef<HTMLUListElement, IShareJobMenu>(
  ({ open, onListKeyDown, onClose, url }, ref) => {
    const dispatch = useAppDispatch();

    const handleCopyToClipBoard = async () => {
      if (isWindow() && navigator) {
        try {
          if (!url) {
            throw new Error();
          }
          await navigator.clipboard.writeText(url);
          dispatch(
            openFeedback({ type: 'success', message: 'linkCopiedToClipboard' }),
          );
          onClose();
        } catch (error) {
          dispatch(
            openFeedback({ type: 'error', message: 'failedToCopyLink' }),
          );
        }
      }
    };

    return (
      <MenuList
        ref={ref}
        autoFocusItem={open}
        id="menu-list"
        onKeyDown={onListKeyDown}
      >
        <MenuItem onClick={handleCopyToClipBoard} sx={shareJobMenuStyles.item}>
          <ShareJobItem icon={faLink as IconProp} text="link" />
        </MenuItem>

        <MenuItem sx={shareJobMenuStyles.item}>
          <FacebookShareButton url={url}>
            <ShareJobItem icon={faFacebook as IconProp} text="withFacebook" />
          </FacebookShareButton>
        </MenuItem>

        <MenuItem sx={shareJobMenuStyles.item}>
          <LinkedinShareButton url={url}>
            <ShareJobItem icon={faLinkedin as IconProp} text="withLinkedin" />
          </LinkedinShareButton>
        </MenuItem>

        <MenuItem sx={shareJobMenuStyles.item}>
          <TwitterShareButton url={url}>
            <ShareJobItem icon={faTwitter as IconProp} text="withTwitter" />
          </TwitterShareButton>
        </MenuItem>
      </MenuList>
    );
  },
);

// Needed to forward refs for click away listener
ShareJobMenu.displayName = 'ShareJobMenu';

ShareJobMenu.defaultProps = {
  onListKeyDown: () => {},
  open: false,
};
