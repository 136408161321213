import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const jobDetailsHeaderStyles = {
  titleContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
    },
  },
  nameText: {
    marginBottom: theme.spacing(0.5),
  },
  salaryText: {
    fontSize: '0.9375rem',
    fontWeight: 600,
    color: palette.info.main,
  },
  desktopContainer: {
    borderRadius: 1,
    padding: `12px`,
    paddingBottom: `8px`,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px',
      paddingBottom: '20px',
    },
  },
  desktopTitleContainer: {
    marginBottom: '8px',
    [theme.breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  netText: {
    fontSize: 'inherit',
    color: 'inherit',
    fontWeight: 400,
  },
};

export const useJobDetailsHeaderStyles = makeStyles(() => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderRadius: 1,
    fontSize: '0.75rem',
    fontWeight: 600,
  },
}));
