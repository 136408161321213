import palette from 'theme/palette';
import theme from 'theme/theme';

export const shareJobMenuStyles = {
  item: {
    '&.MuiMenuItem-root': {
      display: 'block',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      '&:hover': {
        backgroundColor: palette.paleGrey.main,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
      },
    },
  },
  button: {
    '&:focus': {
      outline: 0,
    },
  },
};
