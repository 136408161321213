import theme from 'theme/theme';

export const shareJobItemStyles = {
  item: {
    maxHeight: 25,
  },
  itemText: {
    '& .MuiTypography-root': {
      fontWeight: 400,
      margin: 0,
      fontSize: '1.25rem',
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
    },
  },
  icon: {
    minWidth: 30,
    paddingRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
};
