import { makeStyles } from '@mui/styles';
import palette from 'theme/palette';
import theme from 'theme/theme';

export const useJobDetailsSharedActionsStyles = makeStyles(() => ({
  container: {
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  backgroundOpacity: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: palette.grey.main,
    width: '100%',
    height: '100%',
    opacity: 0.22,
  },
  actionText: {
    fontWeight: 400,
  },
}));
