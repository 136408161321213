/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useCallback, FC } from 'react';
import { ClickAwayListener, Grow, Paper, Popper } from '@mui/material';
import { Button } from '@nploy/ui/web';
import { ShareJobMenu } from 'components/JobDetails';
import { IShareJobButton } from './share-job-button.interface';

export const ShareJobButton: FC<IShareJobButton> = ({ url }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleListKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLUListElement>) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    },
    [],
  );

  return (
    <>
      <Button color="paleGrey" ref={anchorRef} onClick={handleToggle}>
        ...
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <ShareJobMenu
                  open={open}
                  onListKeyDown={handleListKeyDown}
                  onClose={handleClose}
                  url={url}
                />
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
