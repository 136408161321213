import { makeStyles } from '@mui/styles';
import theme from 'theme/theme';

export const useJobDetailsSharedStyles = makeStyles(() => ({
  container: {
    borderRadius: 10,
    flexGrow: 1,
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      flexGrow: 0,
      width: 'min(100%, 788px)',
      marginLeft: 0,
      marginRight: 0,
    },
  },
  body: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
    },
  },
}));
