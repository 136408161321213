import React, { FC } from 'react';
import { Box, ListItemIcon, ListItemText } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslate from 'utils/i18n/useTranslate';
import { IShareJobItem } from './share-job-item.interface';
import { shareJobItemStyles } from './share-job-item.styles';

export const ShareJobItem: FC<IShareJobItem> = ({ icon, text }) => {
  const { t } = useTranslate();

  return (
    <Box display="flex" alignItems="center" sx={shareJobItemStyles.item}>
      <ListItemIcon sx={shareJobItemStyles.icon}>
        <FontAwesomeIcon icon={icon} />
      </ListItemIcon>
      <ListItemText sx={shareJobItemStyles.itemText}>
        {t(`job.share.${text}`)}
      </ListItemText>
    </Box>
  );
};
