export const CANDIDATE_UNMATCH_FEEDBACKS = {
  lackingSkillOrQualification: 1,
  overqualified: 2,
  weFilledThisPosition: 3,
  profileCvIsNotCompleted: 4,
  dontWantSpecify: 5,
};

export const CandidateStatus = {
  assigned: 1,
  newTalents: 2,
  matched: 3,
  unmatched: 4,
  offer: 5,
  interview: 6,
  videoInterview: 7,
};
export const approvedStatuses = [
  CandidateStatus.interview,
  CandidateStatus.matched,
  CandidateStatus.offer,
  CandidateStatus.videoInterview,
];

export const CANDIDATE_JOB_STATUS = {
  notMatchCandidateId: 4,
};
