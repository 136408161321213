import theme from 'theme/theme';

export const jobDerailsSharedHeaderStyles = {
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  сontainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      flexWrap: 'nowrap',
    },
  },
  containerMargins: {
    marginLeft: '5px',
    marginRight: '15px',
  },
  header: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
  },
  nameText: {
    marginBottom: theme.spacing(0.5),
  },
  salaryText: {
    fontSize: '0.9375rem',
    fontWeight: 600,
    color: theme.palette.info.main,
  },
  netText: {
    fontSize: 'inherit',
    color: 'inherit',
    fontWeight: 400,
  },
  companyInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
    },
  },
  logo: {
    maxHeight: 40,
    width: 150,
    height: 50,
    maxWidth: '100%',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 60,
    },
  },
  logoImage: {
    objectPosition: 'right',
    objectFit: 'contain',
  },
  companyName: {
    fontSize: '0.875rem',
  },
};
