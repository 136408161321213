import React, { FC, useMemo } from 'react';
import { Box, Paper, Typography, useMediaQuery, Tooltip } from '@mui/material';
import { Button } from '@nploy/ui/web';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import palette from 'theme/palette';
import theme from 'theme/theme';
import useTranslate from 'utils/i18n/useTranslate';
import { useFiltersValues } from 'utils/swr/hooks/useFiltersValues';
import { ShareJobButton } from 'components/JobDetails/ShareJobButton';
import { JobSalaryText } from 'components/JobsDashboard/job-salary-text';
import { IJobDetailsHeader } from './job-details-header.interface';
import {
  jobDetailsHeaderStyles,
  useJobDetailsHeaderStyles,
} from './job-details-header.styles';

export const JobDetailsHeader: FC<IJobDetailsHeader> = ({
  name,
  salary_range_hidden,
  salary_min,
  salary_max,
  currency,
  onCloseDetails,
  onApply,
  onDiscard,
  shareUrl,
  employmentTypes,
  isCandidateApproved,
  onApplyOrChat,
}) => {
  const classes = useJobDetailsHeaderStyles();
  const { t } = useTranslate();
  const { t: tTopCompanies } = useTranslate('top_companies');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const { filtersValues } = useFiltersValues();

  const salary = useMemo(
    () => (
      <Box sx={jobDetailsHeaderStyles.salaryText}>
        <JobSalaryText
          userSalaryMin={filtersValues.salary_min}
          userSalaryMax={filtersValues.salary_max}
          userCountry={filtersValues.country_id}
          jobSalaryMin={salary_min}
          jobSalaryMax={salary_max}
          jobCurrency={currency}
          jobSalaryRangeHidden={salary_range_hidden}
          jobEmploymentTypes={employmentTypes}
          netTextSx={jobDetailsHeaderStyles.netText}
        />
      </Box>
    ),
    [employmentTypes, filtersValues, salary_min, salary_max, currency],
  );

  return (
    <Box sx={jobDetailsHeaderStyles.titleContainer} component="header">
      {isMobile ? (
        <>
          {/* Name */}
          <Typography variant="h2" sx={jobDetailsHeaderStyles.nameText}>
            {name}
          </Typography>

          {/* Salary */}
          {salary}
        </>
      ) : (
        <Paper elevation={2} sx={jobDetailsHeaderStyles.desktopContainer}>
          <Box mr={2} sx={jobDetailsHeaderStyles.desktopTitleContainer}>
            {/* Name */}
            <Typography variant="h2" sx={jobDetailsHeaderStyles.nameText}>
              {name}
            </Typography>

            {/* Salary */}
            {salary}
          </Box>

          {/* Actions */}
          <Box display="flex" justifyContent={{ lg: 'flex-end' }}>
            {(onApply || isCandidateApproved) && (
              <Button mr={1} onClick={onApplyOrChat}>
                {isCandidateApproved ? t('job.goToChat') : t('job.apply')}
              </Button>
            )}

            {!!(onDiscard && !isCandidateApproved) && (
              <Button mr={1} color="paleGrey" onClick={onDiscard}>
                {t('job.discard')}
              </Button>
            )}

            {shareUrl && <ShareJobButton url={shareUrl} />}

            {onCloseDetails && (
              <Tooltip
                classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
                title={tTopCompanies('backToCompany')}
                placement="bottom"
                arrow
              >
                <Button ml={1} color="paleGrey" onClick={onCloseDetails}>
                  <FontAwesomeIcon
                    icon={faTimes as IconProp}
                    color={palette.black.main}
                    size="lg"
                  />
                </Button>
              </Tooltip>
            )}
          </Box>
        </Paper>
      )}
    </Box>
  );
};
