import React, { FC, useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';
import { Button } from '@nploy/ui/web';
import { checkCandidateApproved } from 'utils/helpers/isCandidateApproved';
import useTranslate from 'utils/i18n/useTranslate';
import { useDislikeJob } from 'utils/swr/hooks/useDislikeJob';
import { useLikeJob } from 'utils/swr/hooks/useLikeJob';
import { useUser } from 'utils/swr/hooks/useUser';
import { useAppDispatch } from 'hooks/*';
import {
  openCreateAccountModal,
  openLoginModal,
} from 'store/reducers/uiReducer';
import { IJobDetailsSharedActions } from './job-details-shared-actions.interface';
import { useJobDetailsSharedActionsStyles } from './job-details-shared-actions.styles';

export const JobDetailsSharedActions: FC<IJobDetailsSharedActions> = ({
  jobId,
  dialogInfo,
  isExternal,
  applyLink,
  timeJobDislike,
  timeJobLike,
}) => {
  const router = useRouter();
  const classes = useJobDetailsSharedActionsStyles();
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const {
    user: { isAuthed },
  } = useUser();
  const { dislike } = useDislikeJob();
  const { like } = useLikeJob();

  const handleOpenCreateAccountModal = () => dispatch(openCreateAccountModal());
  const handleOpenLoginModal = () => dispatch(openLoginModal());
  const isCandidateApproved = checkCandidateApproved(
    dialogInfo?.candidate_job_status_id,
  );

  const isExternalApplied = useMemo(() => {
    if (isExternal) {
      return !!timeJobLike;
    }
    return false;
  }, [isExternal, timeJobLike]);

  const isExternalDisliked = useMemo(() => {
    if (isExternal) {
      return !!timeJobDislike;
    }
    return false;
  }, [isExternal, timeJobDislike]);

  const redirectToHome = useCallback(() => {
    router.push('/');
  }, [router]);

  const handleDislike = useCallback(async () => {
    setLoading(true);
    await dislike(jobId, redirectToHome, null, isExternal);
    setLoading(false);
  }, [redirectToHome, dislike, jobId, isExternal]);

  const handleLike = useCallback(async () => {
    if (isExternal) {
      if (!isExternalApplied) {
        await like(jobId, redirectToHome, null, applyLink);
      } else {
        window.open(applyLink, '_blank');
      }
    } else if (isCandidateApproved) {
      await router.push({
        pathname: `/messages`,
        query: {
          chat: dialogInfo.id,
        },
      });
    } else {
      setLoading(true);
      await like(jobId, redirectToHome);
      setLoading(false);
    }
  }, [
    isExternal,
    jobId,
    isCandidateApproved,
    redirectToHome,
    applyLink,
    like,
    isExternalApplied,
  ]);

  const memoizedAuthedActions = useMemo(() => {
    if (isExternal && (isExternalApplied || isExternalDisliked)) {
      return isExternalApplied ? (
        <Button
          onClick={() => {
            window.open(applyLink, '_blank');
          }}
          mr={3}
          loading={loading}
        >
          {t('job.visitExternal')}
        </Button>
      ) : null;
    }
    return (
      <>
        <Button
          onClick={handleLike}
          mr={3}
          disabled={loading}
          loading={loading}
        >
          {isCandidateApproved ? t('job.goToChat') : t('job.apply')}
        </Button>

        {!isCandidateApproved && (
          <Button
            onClick={handleDislike}
            color="paleGrey"
            disabled={loading}
            loading={loading}
          >
            {t('job.discard')}
          </Button>
        )}
      </>
    );
  }, [isExternalDisliked, isExternalApplied, isExternal, loading, handleLike]);

  return (
    <Box component="footer" className={classes.container}>
      <Box className={classes.backgroundOpacity} />
      {isAuthed ? (
        memoizedAuthedActions
      ) : (
        <>
          <Button onClick={handleOpenCreateAccountModal}>
            {t('createAccount.title')}
          </Button>

          <Box mr={2} ml={2} pt={1} pb={1}>
            <Typography variant="h3" className={classes.actionText}>
              {t('login.or')}
            </Typography>
          </Box>

          <Button color="paleGrey" onClick={handleOpenLoginModal}>
            {t('login.title')}
          </Button>

          <Box mr={2} ml={2} pt={1} pb={1}>
            <Typography variant="h3" className={classes.actionText}>
              {t('login.toApply')}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};
