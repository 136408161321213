import { createAsyncThunk } from '@reduxjs/toolkit';
import { useCallback } from 'react';
import { axios } from '@nploy/shared';
import { useAppDispatch, useAppSelector } from 'hooks/*';
import { openFeedback } from 'store/reducers/feedbackReducer';
import { setExternalLikedId, setLikedId } from 'store/reducers/jobsReducer';
import {
  openBuildCVModal,
  openCreateAccountModal,
} from 'store/reducers/uiReducer';
import { AppDispatch } from 'store/store';
import { eventNames, logAnalyticsEvent } from '../../analytics/event';
import { handleError } from '../helpers/errorHandling';
import { useUser } from './useUser';

const createPostLike = createAsyncThunk<
  void,
  {
    jobId: number;
    callback?: () => void;
    onFail?: () => void;
    isExternal?: boolean;
  },
  { dispatch: AppDispatch }
>(
  'jobs/apply-jobs',
  async ({ jobId, callback, onFail, isExternal }, { dispatch }) => {
    try {
      if (isExternal) {
        await axios.post(`/jobs/scraper-job/${jobId}/liked/2`);
      } else {
        await axios.post(`/job/${jobId}/assign`);
      }
      if (callback) callback();
    } catch (error) {
      handleError(error, dispatch);
      if (onFail) onFail();
    }
  },
);

export const useLikeJob = () => {
  const dispatch = useAppDispatch();
  const {
    user: { isAuthed },
  } = useUser();
  const { fullComplete: isCVCompleted } = useAppSelector(({ cv }) => cv);

  const like = useCallback(
    async (
      jobId: number,
      onSuccess?: () => void,
      onFail?: () => void,
      externalApplyLink?: string,
    ) => {
      if (isAuthed) {
        if (isCVCompleted) {
          if (externalApplyLink) {
            window.open(externalApplyLink, '_blank');
          }
          await dispatch(
            createPostLike({
              jobId,
              callback: () => {
                if (onSuccess) onSuccess();

                logAnalyticsEvent(eventNames.apply, {
                  category: jobId,
                  label: isAuthed ? 'registered_user' : 'unregistered_user',
                  is_external: !!externalApplyLink,
                });

                if (externalApplyLink) {
                  dispatch(setExternalLikedId(jobId));
                } else {
                  dispatch(setLikedId(jobId));
                }
                dispatch(
                  openFeedback({
                    type: 'success',
                    message: 'applicationSuccessful',
                  }),
                );
              },
              onFail,
              isExternal: !!externalApplyLink,
            }),
          );
        } else {
          dispatch(openBuildCVModal());
          if (onFail) {
            onFail();
          }
        }
      } else {
        dispatch(openCreateAccountModal());
        if (onFail) {
          onFail();
        }
      }
    },
    [isAuthed, isCVCompleted, dispatch],
  );

  return {
    like,
  };
};
