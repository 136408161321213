import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import theme from 'theme/theme';
import { getPostedAgoTime } from 'utils/helpers/jobs';
import JobChips from '../../../../components/JobsDashboard/JobChips';
import useTranslate from '../../../../utils/i18n/useTranslate';

const JobDetailsBody = ({
  description,
  detailsLoading,
  skills,
  languages,
  benefits,
  className,
  dynamicHeightOnMdUp,
  publishedAt,
}) => {
  const classes = useStyles();
  const { t } = useTranslate();
  const containerRef = useRef(null);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [maxHeight, setMaxHeight] = useState('unset');

  const calculateMaxHeight = () => {
    if (isMobile || !dynamicHeightOnMdUp) return;

    if (!containerRef?.current) {
      setMaxHeight('unset');
    }

    const allowedHeight =
      window.innerHeight - containerRef?.current?.getBoundingClientRect().y - 8;

    setMaxHeight(allowedHeight);
  };

  useEffect(() => {
    calculateMaxHeight();
  }, [description]);

  useEffect(() => {
    calculateMaxHeight();
    window.addEventListener('scroll', calculateMaxHeight, { passive: true });

    return () => {
      window.removeEventListener('scroll', calculateMaxHeight, {
        passive: true,
      });
    };
  }, []);

  return (
    <Box
      className={classnames(
        classes.body,
        !!publishedAt && classes.denseBottomPadding,
        className,
      )}
      style={{
        maxHeight: isMobile || !dynamicHeightOnMdUp ? 'unset' : maxHeight,
      }}
      ref={containerRef}
    >
      {(!!description || detailsLoading) && (
        <Box className={classes.sectionContainer}>
          <Typography
            variant="h4"
            color="textSecondary"
            className={classnames(
              classes.sectionTitle,
              classes.descriptionTitle,
            )}
          >
            {t('job.responsibilities')}
          </Typography>

          {detailsLoading ? (
            <Typography
              variant="body1"
              className={classes.descriptionText}
              component="div"
            >
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Typography>
          ) : (
            <Typography
              variant="body1"
              className={classes.descriptionText}
              component="div"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </Box>
      )}

      <Box className={classes.sectionContainer}>
        <Typography
          variant="h4"
          color="textSecondary"
          className={classes.sectionTitle}
        >
          {t('job.requiredSkills')}
        </Typography>

        <JobChips blackItems={skills} greenItems={languages} detailedView />
      </Box>

      {!!benefits?.length && (
        <Box className={classes.sectionContainer}>
          <Typography
            variant="h4"
            color="textSecondary"
            className={classes.sectionTitle}
          >
            {t('job.benefits')}
          </Typography>

          <JobChips blackItems={benefits} detailedView />
        </Box>
      )}

      {!!publishedAt && (
        <Box className={classes.publishedAtContainer}>
          <Typography
            variant="body1"
            component="span"
            className={classes.publishedAtText}
          >
            {getPostedAgoTime(publishedAt, t)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  body: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  denseBottomPadding: {
    [theme.breakpoints.up('lg')]: {
      paddingBottom: theme.spacing(2.8),
    },
  },
  sectionContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  descriptionTitle: {
    marginBottom: theme.spacing(1),
  },
  descriptionText: {
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  chipsContainer: {
    marginTop: theme.spacing(1),
  },
  publishedAtContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: -theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: -theme.spacing(1),
    },
  },
  publishedAtText: {
    fontWeight: 400,
    fontSize: '0.75rem',
    color: theme.palette.darkGrey.main,
  },
}));

JobDetailsBody.propTypes = {
  description: PropTypes.string,
  detailsLoading: PropTypes.bool,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  skills: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
  ),
  languages: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }),
  ),
  className: PropTypes.string,
  dynamicHeightOnMdUp: PropTypes.bool,
  publishedAt: PropTypes.string,
};

JobDetailsBody.defaultProps = {
  description: '',
  detailsLoading: false,
  benefits: [],
  skills: [],
  languages: [],
  className: '',
  dynamicHeightOnMdUp: false,
  publishedAt: '',
};

export default JobDetailsBody;
